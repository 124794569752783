export default [
  {
    title: 'Termos e privacidade',
    paragraphs: [
      'Com as novas regras de <b>proteção de dados pessoais</b> no país, estamos atualizando nossas políticas e documentos para trazer mais <b>transparência</b> aos clientes e usuários, bem como melhorar nossos serviços. Assim, em cumprimento à legislação, precisamos registrar a sua anuência, na qualidade de Administrador Master (total ou parcial), aos termos abaixo.',
      'Os dados pessoais, sensíveis ou não, disponibilizados à CONVENIA pelo CLIENTE foram <b>obtidos de maneira legal</b>, sendo estritamente necessários para o cumprimento de obrigações comerciais/contratuais ou cumprimento de obrigações decorrentes de lei, e, portanto, o CLIENTE tem justa base legal para utilizar os dados pessoais de seus empregados e contratados, por si, seus empregados ou por terceiros, tais como a CONVENIA, bem como os dados de seus dependentes, quando for o caso (“Dados”).',
      'As informações que devam ser preenchidas pelos usuários em formulário online padrão da CONVENIA, inclusive que sejam consideradas como dados pessoais, são decorrentes de informações necessárias ao cumprimento de obrigações legais pelo CLIENTE, quaisquer outras informações que sejam adicionadas pelo CLIENTE em campos de livre preenchimento são de sua integral responsabilidade, sendo ou não estas consideradas dados pessoais.',
      'A <b>finalidade do tratamento</b> dos Dados pela CONVENIA será a de prestar os serviços contratados pelo CLIENTE, sendo certo que a CONVENIA poderá inclusive fornecer tais dados para terceiros ou seus parceiros, quando necessário para a prestação dos referidos serviços.',
      'Considerando a relação entre o CLIENTE e a CONVENIA, ambos deverão assegurar-se de que os seus colaboradores, consultores e prestadores de serviços, no exercício das suas funções, devam ter acesso e conhecimento da informação ou dos dados pessoais para fins de <b>execução dos serviços contratados</b> e demais finalidades de tratamento acordadas pelas partes e/ou permitidas pela legislação, sendo sempre observadas as normas referentes: (i) à proteção de dados pessoais; (ii) ao tratamento das informações; (iii) à transferência de dados.',
      'O CLIENTE, neste ato, declara concordar com os termos acima e que adota/adotará as medidas necessárias para o seu cumprimento.'
    ]
  }
]
