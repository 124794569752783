<template>
  <div class="terms-description">
    <div class="container">
      <img v-if="logo" class="logo" :src="logo" />
      <c-logo v-else class="logo" arcColor="purple" width="130" />

      <div
        class="section"
        v-for="item in terms"
        :key="item.title"
      >
        <c-title
          v-if="item.title"
          class="title"
          size="2"
          darkText
        >
          {{ item.title }}
        </c-title>
        <p
          v-for="paragraph in item.paragraphs"
          class="text"
          :key="paragraph"
          v-html="paragraph"
        />
      </div>

      <div class="actions">
        <c-button
          class="button"
          size="40"
          flat
          @click="$emit('refuse')"
        >Recusar</c-button>

        <c-button
          class="button"
          size="40"
          @click="$emit('accept')"
        >Aceitar</c-button>
      </div>
    </div>
  </div>
</template>

<script>
import terms from '@content/Terms/lgpd'

export default {
  props: {
    logo: {
      type: String,
      default: ''
    }
  },

  data () {
    return { terms }
  },

  beforeMount () {
    document.body.style.overflowY = 'auto'
  },

  beforeDestroy () {
    document.body.style.overflowY = 'unset'
  }
}
</script>

<style lang="scss">
.terms-description {
  position: relative;
  margin: {
    top: 20px;
    left: 20px;
    bottom: 50px;
    right: 20px;
  }

  & > .container {
    width: 100%;

    & > .section:not(:first-child) { margin-top: 30px; }

    & > .section > .text {
      margin-top: 20px;
      font-size: 14px;

      & > a { color: rgba(18, 30, 72, 0.8) }
    }

    & > .logo { width: 130px; }
    & > .section > .c-title > .border { background: #940DFF; }
  }

  @include mobile (true) {
    margin-top: 100px;

    & > .container {
      max-width: 980px;

      & > .section > .text { font-size: 16px; }
    }
  }

  @include tablet (true) {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    & > .container { margin-top: 20px; }
  }

  & > .container > .actions {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    width: 100%;
    margin-top: 80px;

    & > .c-button {
      flex: 0 180px;
      &:not(.-flat) {
        background: #940DFF;
        & > .text { text-shadow: none; }
      }
      & + .c-button { margin-left: 20px; }
    }
  }
}
</style>
