<template>
  <lgpd-terms
    v-if="fetched"
    :loading="loading"
    :logo="logo"
    @accept="accept"
    @refuse="refuse"
  />
</template>

<script>
import LgpdTerms from '@components/terms/Lgpd'
import axios from 'axios'
import Cookie from 'js-cookie'
import decode from 'jwt-decode'
import logo from '../assets/logo_new.svg'

export default {
  components: { LgpdTerms },

  data () {
    return {
      token: Cookie.get('token'),
      loading: false,
      fetched: false,
      logo
    }
  },

  methods: {
    async post (path, params) {
      const headers = {
        Authorization: `Bearer ${this.token}`,
        'Content-Type': 'application/json'
      }

      return await axios.post(`${process.env.VUE_APP_API}/api/v1${path}`, params, { headers }) || {}
    },

    async refreshToken () {
      const { data: { data: { api_token: token } = {} } = {} } = await this
        .post('/users/new-token')

      if (!token) return

      const domain = process.env.VUE_APP_COOKIE_DOMAIN
      Cookie.set('token', token, { domain })
      this.token = token

      return token
    },

    async accept () {
      this.loaging = true
      const { data: { success } = {} } = await this
        .post('/lgpd/consent', { consent: true })

      if (!success) this.loading = false

      await this.refreshToken()

      return window.location.href = process.env.VUE_APP_REDIRECT
    },
    async refuse () {
      this.loading = true
      const { data: { success } = {} } = await this
        .post('/lgpd/consent', { consent: false })

      if (!success) {
        this.loading = false
        return console.log('tb nufoi')
      }

      return window.location.href = process.env.VUE_APP_LOGOUT
    }
  },

  async created () {
    if (!this.token) return this.$router.push({ name: 'signin' })

    const token = await this.refreshToken()
    const { consent } = decode(token) || {}
    if (consent) return window.location.href = process.env.VUE_APP_REDIRECT
    this.fetched = true
  }
}
</script>
